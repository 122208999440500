import { graphql } from "gatsby";
import React from "react";
import { useSelector } from "react-redux";
import Layout from "components/Layout";
import { Link } from "gatsby";
import LayoutFloating from "../components/LayoutFloating";

const NotFoundPage = () => {
  const { unique_region_text } = useSelector(
    (state) => !state.region_loading && state.region_data
  );

  return (
    <Layout
      pageName="error-page"
      showHeader={false}
      siteTitle={unique_region_text?.siteTitle ?? ""}
      siteDescription={unique_region_text?.siteDescription ?? ""}
    >
      <LayoutFloating title={unique_region_text?.siteTitle ?? ""}>
        <section className="px-5 pt-3 pb-5 text-center">
          <h1>Internal Error</h1>
          <p>Sorry for the inconvenience.</p>
          <Link to="/" className="btn btn-lg btn-primary">
            Return home
          </Link>
        </section>
      </LayoutFloating>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
